console.log "Hello there! It's nice that you are looking under the hood. The source code for this page is here: https://gitlab.com/software-garden/website"

start_carousel = (parent, interval = 10000) =>
    offset = 0

    slides = parent.children

    indicators = document.createElement "ul"
    indicators.classList = [ "indicators" ]

    for slide in slides
        indicator = document.createElement "li"
        indicators.appendChild indicator

    # parent.insertAdjacentElement("afterend", indicators)

    show_slide = (n) =>
        for indicator in indicators.children
            indicator.classList = []

        for slide in slides
            slide.classList = []

        slides[n].classList = [ "current" ]
        indicators.children[n].classList = [ "current" ]
        slides[0].style.marginLeft = "-#{ n * 100 }%"

    advance = () =>
        offset = (offset + 1) % parent.children.length
        show_slide offset

    show_slide 0

    setInterval advance, interval

for element in document.querySelectorAll ".carousel"
    start_carousel element

menu_toggle = document.getElementById "menu-toggle"
menu_toggle.addEventListener "change", () =>
    window.scroll 0, 0
    document.body.dataset.menu = menu_toggle.checked
